import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import {Col, Row} from 'react-bootstrap'
import {graphql, Link} from "gatsby"
import AlphabetSelector from "../components/lexikon/AlphabetSelector"
import Seo from "../components/App/Seo"

const LexikonOverview = ({data}) => {

  let alphabetCount = {};
  data.allMarkdownRemark.edges.forEach(({ node: qaEntry }) => {
    alphabetCount[qaEntry.frontmatter.question[0]] =
      alphabetCount[qaEntry.frontmatter.question[0]] === undefined ?
        {
          counter: 1,
          data: [qaEntry]
        } :
        {
          counter: alphabetCount[qaEntry.frontmatter.question[0]].counter + 1,
          data: alphabetCount[qaEntry.frontmatter.question[0]].data.concat([qaEntry])
        }
  })

  return (
    <Layout>
      <Seo title={"Das Logistik Lexikon by LOGENTIS"}
           description={"Erfahren, was die Logistik und uns bewegt: Das Logentis Logistik-Lexikon erklärt aktuelle Fachbegriffe von A wie ABC-Analyse bis Z wie Zoll."}
           image={"/images/og/lexikon-logentis-og.png"}
      />
      <Navbar />
      <PageBanner
        bannerClassname={"page-title-area2"}
        pageTitle="LOGENTIS Logistik Lexikon"
        homePageText="Über uns"
        homePageUrl="/foo/"
        activePageText="Lexikon"
        breadcrumbs={[["Home", "/"]]}
      />
      <section className="history-area ptb-100 bg-fafafb">

        <div className={"container w-75"}>
          <AlphabetSelector
            alphabetCount={alphabetCount}
            title={"Erfahren, was die Logistik und uns bewegt"}
            subtitle={<span style={{fontSize: '1.2em'}}>Das <strong>Logentis Logistik-Lexikon</strong> erklärt aktuelle Fachbegriffe von A wie ABC-Analyse bis Z wie Zoll.</span>}
          />
        </div>

        {Object.keys(alphabetCount).map((key) => (
        <div id={"L_" + key} key={key} className={"d-flex mt-5"} style={{justifyContent: "center"}}>
          <div className="jumbotron w-75">
            <div className={"d-flex"}>
              <div className="display-3 p-2 bg-dark text-white">{key}</div>
              <div style={{alignSelf: "flex-end"}} className={"ml-2"}>
                ({alphabetCount[key].data.length} {alphabetCount[key].data.length > 1 ? "Einträge" : "Eintrag"})
            </div>

            </div>
            <hr className="my-4"/>
            <Row>
              {alphabetCount[key].data.map((data) => (
                <Col sm={6} xl={4} key={data.id}><Link to={data.fields.slug}>{data.frontmatter.question}</Link></Col>
              ))}
            </Row>
          </div>
        </div>
        ))}
      </section>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query qaQuery1 {
    allMarkdownRemark(
      filter: {fields: {collection: {eq: "qa"}}, frontmatter: {disabled: {ne: true}}},sort: {fields: frontmatter___question}) {
      edges {
        node {
          id
          frontmatter {
            question
          }
          fields {
            collection
            slug
          }
          html
        }
      }
    }
  }
`

export default LexikonOverview
